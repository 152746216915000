import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WebformFieldData } from 'src/app/api-model/webform-schema';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(private readonly httpClient: HttpClient) {}

  public getForm(id: string): Observable<Record<string, WebformFieldData>> {
    return this.httpClient.get<Record<string, WebformFieldData>>(
      `${environment.baseUrl}webform_rest/${id}/fields?_format=json`
    );
  }
}
