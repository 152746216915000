import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface MenuEntry {
  label: string;
  route: string;
  icon: string;
}

export type Menu = MenuEntry[];

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private blockPath = '/block/';

  constructor(private readonly httpClient: HttpClient) {}

  // TODO: Getting the Menu from Drupal via REST is actual not that easy.
  // So we had to implement it static at the moment.
  // Only Module known is for Drupal ^9

  public getMainMenu(): Menu {
    const menu: Menu = [
      {
        label: 'Home',
        route: '/home',
        icon: 'home-sharp',
      },
      {
        label: 'Entwicklung',
        route: '/angebot/entwicklung',
        icon: 'code-slash-sharp',
      },
      {
        label: 'Coaching',
        route: '/angebot/coaching',
        icon: 'rocket-sharp',
      },
      {
        label: 'Team',
        route: '/team',
        icon: 'happy-sharp',
      },
      {
        label: 'Kontakt',
        route: '/kontakt',
        icon: 'chatbubbles-sharp',
      },
    ];

    return menu;
  }

  public getFooterMenu(): Menu {
    const footerMenu: Menu = [
      {
        label: 'Datenschutz',
        route: '/datenschutz',
        icon: 'document-lock-sharp',
      },
      {
        label: 'Impressum',
        route: '/impressum',
        icon: 'document-text-sharp',
      },
    ];

    return footerMenu;
  }

  public getSocialLink(): Menu {
    const socialMenu: Menu = [
      {
        label: 'LinkedIn',
        route: 'https://de.linkedin.com/company/hakigo-gmbh',
        icon: 'logo-linkedin',
      },
      {
        label: 'XING',
        route: 'https://www.xing.com/pages/hakigogmbh',
        icon: 'logo-xing',
      },
    ];
    return socialMenu;
  }
}
