<div class="alternative-background">
  <ion-grid [fixed]="true">
    <ion-row class="ion-justify-content-center">
      <h2>{{ this.logoCollection.title }}</h2>
    </ion-row>
    <ion-row class="bottom-row-padding">
      <ion-col>
        <swiper-container
          autoplay
          [slidesPerView]="1"
          [breakpoints]="this.breakpoints"
          [loop]="true"
        >
          <swiper-slide class="ion-text-center" *ngFor="let logo of this.logoCollection.logos">
            <img [title]="logo.title" [src]="logo.src" [alt]="logo.alt" width="205" height="36" />
          </swiper-slide>
          <!-- In swiper 9 we need more logos than we have -->
          <swiper-slide class="ion-text-center" *ngFor="let logo of this.logoCollection.logos">
            <img [title]="logo.title" [src]="logo.src" [alt]="logo.alt" width="205" height="36" />
          </swiper-slide>
        </swiper-container>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
