import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { LinkifyDirective } from 'src/app/directives/linkify.directive';

@Component({
  standalone: true,
  imports: [IonicModule, CommonModule, LinkifyDirective],
  selector: 'app-hero-text-block',
  templateUrl: './hero-text-block.component.html',
  styleUrls: ['./hero-text-block.component.scss'],
})
export class HeroTextBlockComponent {
  @Input()
  public body = '';

  @Input()
  public centered = false;

  @Input()
  public onFrontpage = true;
}
