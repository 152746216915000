<form [formGroup]="this.form" (ngSubmit)="this.onSubmit()" mode="md">
  <ion-grid fixed>
    <ion-row>
      <ion-col class="ion-padding">
        <h1>{{ this.formTitle }}</h1>
        <p>{{ this.formDescription }}</p>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="this.submitted">
      <ion-col>
        <ion-card color="success">
          <ion-card-content>
            {{ this.submitText }}
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
    <ion-row *ngFor="let field of this.fields" class="ion-padding">
      <ion-col>
        <!--TODO Error messages-->
        <app-form-field [data]="field" [form]="this.form" />
      </ion-col>
    </ion-row>
  </ion-grid>
</form>
