<ion-toolbar mode="md">
  <ion-buttons slot="start" class="logo">
    <ion-button [routerLink]="['/home']">
      <ion-img src="../../../../assets/icon/favicon.png" alt="Logo" />
    </ion-button>
  </ion-buttons>

  <ion-buttons slot="end" class="menu ion-hide-sm-down">
    <ion-menu-button [autoHide]="false">
      <ion-icon src="../../../../assets/buttons/menu.svg" />
    </ion-menu-button>
  </ion-buttons>
</ion-toolbar>
