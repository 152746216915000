import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Menu, MenuService } from 'src/app/services/menu/menu.service';

import { MenuComponent } from '../../dumb-components/menu/menu.component';

@Component({
  standalone: true,
  selector: 'app-menu-smart',
  templateUrl: './menu-smart.component.html',
  styleUrls: [],
  imports: [CommonModule, IonicModule, MenuComponent],
})
export class MenuSmartComponent {
  public mainNav: Menu;
  public footerNav: Menu;

  constructor(private readonly menuService: MenuService) {
    this.mainNav = this.menuService.getMainMenu();
    this.footerNav = this.menuService.getFooterMenu();
  }
}
