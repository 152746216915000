import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { Observable, map } from 'rxjs';
import { LogoService } from 'src/app/services/logo/logo.service';

import { LogoRowComponent } from '../../dumb-components/logo-components/logo-row/logo-row.component';
import { Logos } from '../../dumb-components/logo-components/logos';

@Component({
  standalone: true,
  selector: 'app-sponsors',
  templateUrl: './sponsors.component.html',
  imports: [IonicModule, RouterModule, CommonModule, LogoRowComponent],
})
export class SponsorsComponent {
  public logos: Observable<Logos>;
  public title = 'Gefördert durch/Mitglied bei';

  constructor(private readonly logoService: LogoService) {
    this.logos = this.logoService.getSupporterLogos().pipe(
      map((logos) => {
        return { title: this.title, logos: logos.logos };
      })
    );
  }
}
