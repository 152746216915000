import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { HeroTextBlockComponent } from '../../dumb-components/hero-text-block/hero-text-block.component';
import { BaseBlockComponent } from '../base-block/base-block.component';

@Component({
  standalone: true,
  selector: 'app-greeting-block',
  templateUrl: './greeting-block.component.html',
  styleUrls: ['./greeting-block.component.scss'],
  imports: [CommonModule, RouterModule, IonicModule, HeroTextBlockComponent],
})
export class GreetingBlockComponent extends BaseBlockComponent {
  protected override getId(): string {
    return '3';
  }
}
