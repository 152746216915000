import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { BlockContentBasicSchema } from 'src/app/api-model/api-schema';
import { CollectionsSchema } from 'src/app/api-model/collection-schema';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BlocksService {
  constructor(private readonly httpClient: HttpClient) {}

  public getBlock(id: string): Observable<BlockContentBasicSchema['data']> {
    return this.httpClient
      .get<CollectionsSchema<BlockContentBasicSchema>>(
        `${environment.apiUrl}block_content/basic?filter[drupal_internal__id]=${id}`
      )
      .pipe(
        map((response: CollectionsSchema<BlockContentBasicSchema>) => {
          if (!response.data[0]) {
            throw new Error(); //TODO
          }
          return response.data[0];
        })
      );
  }
}
