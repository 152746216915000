import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { Menu } from 'src/app/services/menu/menu.service';
import { register } from 'swiper/element/bundle';

register();

@Component({
  standalone: true,
  selector: 'app-menu-component',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  imports: [IonicModule, CommonModule, RouterModule],
})
export class MenuComponent {
  @Input()
  public mainNav: Menu = [];

  @Input()
  public footerNav: Menu = [];
}
