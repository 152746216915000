<div class="alternative-background footer" mode="md">
  <ion-grid [fixed]="true" class="ion-padding-start ion-padding-end">
    <ion-row class="ion-padding-top ion-padding-bottom ion-text-lowercase">
      <ion-col size="12" size-md="4">
        <ion-row class="ion-padding-bottom">
          <ion-col class="ion-no-padding">
            <a [routerLink]="['/home']">
              <ion-img
                class="ion-float-left"
                src="../../../../assets/images/logo_footer.png"
                alt="hakigo"
              />
            </a>
          </ion-col>
        </ion-row>
        <ion-row class="ion-no-padding">
          <ion-item lines="none" color="dark">
            <ion-text [innerHTML]="this.webfooterData.firstColumn" />
          </ion-item>
        </ion-row>
      </ion-col>

      <ion-col size="12" size-md="2">
        <h2>{{ this.webfooterData.secondColumnHeading }}</h2>
        <ion-list color="dark" lines="none">
          <ion-item
            color="dark"
            *ngFor="let navItem of this.webfooterData.secondColumn"
            [routerLink]="[navItem.route]"
          >
            <ion-label>{{ navItem.label }}</ion-label>
          </ion-item>
        </ion-list>
      </ion-col>

      <ion-col size="12" size-md="3">
        <h2>{{ this.webfooterData.thirdColumnHeading }}</h2>
        <ion-list color="dark" lines="none">
          <ion-item
            color="dark"
            class="socials"
            *ngFor="let social of this.webfooterData.thirdColumn"
            href="{{ social.route }}"
          >
            <ion-icon [name]="social.icon" slot="start" />
            <ion-label>{{ social.label }}</ion-label>
          </ion-item>
        </ion-list>
      </ion-col>

      <ion-col size="12" size-md="3">
        <h2>{{ this.webfooterData.fourthColumnHeading }}</h2>
        <ion-list lines="none" color="dark">
          <ion-item color="dark">
            <app-modal />
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>

    <app-sponsors />

    <ion-row>
      <ion-col size="12">
        <ion-list lines="none" color="dark">
          <ion-item
            color="dark"
            *ngFor="let legal of this.webfooterData.bottomLinks"
            class="ion-float-left legals"
            [routerLink]="[legal.route]"
            >{{ legal.label }}</ion-item
          >
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
