import { CommonModule, KeyValuePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { Menu } from 'src/app/services/menu/menu.service';

import { IncludedFilterPipe } from '../../../pipes/included-filter/included-filter.pipe';
import { ModalComponent } from '../../every-page-components/modal/modal.component';
import { SponsorsComponent } from '../../logos/sponsors/sponsors.component';

export interface WebFooterData {
  secondColumnHeading: string;
  thirdColumnHeading: string;
  fourthColumnHeading: string;

  firstColumn: string;
  secondColumn: Menu;
  thirdColumn: Menu;
  fourthColumn: string;

  bottomLinks: Menu;
}
@Component({
  standalone: true,
  selector: 'app-web-footer',
  templateUrl: './web-footer.component.html',
  styleUrls: ['./web-footer.component.scss'],
  imports: [
    IonicModule,
    RouterModule,
    KeyValuePipe,
    CommonModule,
    IncludedFilterPipe,
    ModalComponent,
    SponsorsComponent,
  ],
})
export class WebFooterComponent {
  @Input({ required: true })
  public webfooterData: WebFooterData = {
    secondColumnHeading: '',
    thirdColumnHeading: '',
    fourthColumnHeading: '',

    firstColumn: '',
    secondColumn: [],
    thirdColumn: [],
    fourthColumn: '',

    bottomLinks: [],
  };
}
