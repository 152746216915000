import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IonicModule } from '@ionic/angular';

@Component({
  standalone: true,
  selector: 'app-mobile-menu-button',
  templateUrl: './mobile-menu-button.component.html',
  styleUrls: [],
  imports: [CommonModule, IonicModule],
})
export class MobileMenuButtonComponent {
  @Input()
  public size = 'normal';

  @Input()
  public color = 'primary';
}
