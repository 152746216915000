import { AfterViewInit, Component, Input, Type } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { WebformNamedFieldData } from 'src/app/api-model/webform-schema';
import { DynamicComponent } from 'src/app/components/generic-components/dynamic-component/dynamic-component.component';

import { BaseFormFieldComponent } from './base-form-field/base-form-field.component';
import { ButtonFormFieldComponent } from './button-form-field/button-form-field.component';
import { InputFormFieldComponent } from './input-form-field/input-form-field.component';
import { TextareaFormFieldComponent } from './textarea-form-field/textarea-form-field.component';

@Component({
  standalone: true,
  selector: 'app-form-field',
  templateUrl: '../../../generic-components/dynamic-component/dynamic-component.component.html',
})
export class FormFieldComponent
  extends DynamicComponent<WebformNamedFieldData, BaseFormFieldComponent>
  implements AfterViewInit
{
  @Input()
  public override data: WebformNamedFieldData = {
    '#name': '',
    '#title': '',
    '#type': '',
    '#webform': '',
  };

  @Input()
  public form?: FormGroup;

  public control?: FormControl;

  public override ngAfterViewInit(): void {
    super.ngAfterViewInit();

    if (this.dynamicInstance) {
      this.control = new FormControl('', this.dynamicInstance.instance.getValidators());
      this.form?.addControl(this.data['#name'], this.control);
      this.dynamicInstance.instance.control = this.control;
    }
  }

  protected getComponentClass(): Type<BaseFormFieldComponent> {
    switch (this.data['#type']) {
      case 'button':
      case 'webform_actions':
      case 'submit':
        return ButtonFormFieldComponent;
      case 'textarea':
        return TextareaFormFieldComponent;
      default:
        return InputFormFieldComponent;
    }
  }
}
