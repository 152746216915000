<ng-container *ngIf="this.logos">
  <ion-row class="ion-no-padding">
    <ion-col class="logo-row-title ion-text-center ion-no-padding">{{ this.logos.title }}</ion-col>
  </ion-row>
  <ion-row class="ion-padding logo-row-logos ion-justify-content-center">
    <ion-col size="3" *ngFor="let logo of this.logos.logos">
      <ion-img [title]="logo.title" [src]="logo.src" [alt]="logo.alt" />
    </ion-col>
  </ion-row>
</ng-container>
