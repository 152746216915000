import { CommonModule, KeyValuePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { Logos } from '../logos';

@Component({
  standalone: true,
  selector: 'app-logo-row',
  templateUrl: './logo-row.component.html',
  styleUrls: ['./logo-row.component.scss'],
  imports: [IonicModule, RouterModule, KeyValuePipe, CommonModule],
})
export class LogoRowComponent {
  @Input({ required: true })
  public logos: Logos = { title: '', logos: [] };
}
