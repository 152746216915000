import { Component } from '@angular/core';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import { WebformNamedFieldData } from 'src/app/api-model/webform-schema';
import { DynamicElement } from 'src/app/components/generic-components/dynamic-component/dynamic-element';

export type ValidatorList = ValidatorFn[];
@Component({
  template: '<div>Abstract class</div>',
})
export abstract class BaseFormFieldComponent implements DynamicElement<WebformNamedFieldData> {
  public control = new FormControl();

  public field: WebformNamedFieldData = {
    '#name': '',
    '#title': '',
    '#type': '',
    '#webform': '',
  };

  public setData(data: WebformNamedFieldData): void {
    this.field = data;
  }

  public getValidators(): ValidatorList {
    const validators: ValidatorList = [];
    if (this.field['#required']) {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      validators.push(Validators.required);
    }
    return validators;
  }
}
