import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Observable, forkJoin, map } from 'rxjs';
import { BlockContentBasicSchema } from 'src/app/api-model/api-schema';
import { BlocksService } from 'src/app/services/blocks/blocks.service';
import { LogoService } from 'src/app/services/logo/logo.service';
import { MenuService } from 'src/app/services/menu/menu.service';

import { Logos } from '../../dumb-components/logo-components/logos';
import {
  WebFooterComponent,
  WebFooterData,
} from '../../dumb-components/web-footer/web-footer.component';

@Component({
  standalone: true,
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  imports: [WebFooterComponent, CommonModule],
})
export class FooterComponent {
  public webfooterData: Observable<WebFooterData>;

  constructor(
    private readonly menuService: MenuService,
    private readonly blockService: BlocksService,
    private readonly logoService: LogoService
  ) {
    const block: Observable<BlockContentBasicSchema['data']> = this.blockService.getBlock('1');

    const address: Observable<string> = block.pipe(
      map((data) => {
        if (!data.attributes?.body?.value) {
          return ''; // Should throw error;
        }

        return data.attributes.body.value;
      })
    );

    const logos: Observable<Logos> = this.logoService.getSupporterLogos().pipe(
      map((logos) => {
        return { title: 'Förderer', logos: logos.logos };
      })
    );

    this.webfooterData = forkJoin({
      address: address,
      logos: logos,
    }).pipe(
      map((data) => {
        return {
          secondColumnHeading: 'Menu',
          thirdColumnHeading: 'Socials',
          fourthColumnHeading: 'Kontakt',

          firstColumn: data.address,
          secondColumn: this.menuService.getMainMenu(),
          thirdColumn: this.menuService.getSocialLink(),
          fourthColumn: '',

          bottomLinks: this.menuService.getFooterMenu(),
          bottomLogos: data.logos,
        };
      })
    );
  }
}
