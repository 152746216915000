import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GreetingBlockComponent } from './components/custom-block/greeting-block/greeting-block.component';
import { LogoSwiperComponent } from './components/dumb-components/logo-components/logo-swiper/logo-swiper.component';
import { MobileMenuButtonComponent } from './components/dumb-components/mobile-menu-button/mobile-menu-button.component';
import { WebFooterComponent } from './components/dumb-components/web-footer/web-footer.component';
import { FooterComponent } from './components/every-page-components/footer/footer.component';
import { HeaderComponent } from './components/every-page-components/header/header.component';
import { MenuSmartComponent } from './components/every-page-components/menu-smart/menu-smart.component';

export type ArrayElement<ArrayType extends readonly unknown[] | undefined> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : undefined;

@NgModule({
  declarations: [AppComponent],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    HeaderComponent,
    FooterComponent,
    WebFooterComponent,
    GreetingBlockComponent,
    LogoSwiperComponent,
    MenuSmartComponent,
    MobileMenuButtonComponent,
  ],
})
export class AppModule {}
