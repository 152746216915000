import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { BaseFormFieldComponent } from '../base-form-field/base-form-field.component';

@Component({
  standalone: true,
  selector: 'app-input-form-field',
  templateUrl: './input-form-field.component.html',
  imports: [IonicModule, FormsModule, ReactiveFormsModule, CommonModule],
})
export class InputFormFieldComponent extends BaseFormFieldComponent {
  public override getValidators(): ValidatorFn[] {
    const validators = super.getValidators();

    if (this.field['#type'] === 'email') {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      validators.push(Validators.email);
    }

    return validators;
  }
}
