import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, Input } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { SwiperOptions } from 'swiper';
import { register } from 'swiper/element/bundle';

import { IncludedFilterPipe } from '../../../../pipes/included-filter/included-filter.pipe';
import { Logos } from '../logos';

register();

@Component({
  standalone: true,
  selector: 'app-logo-swiper',
  templateUrl: './logo-swiper.component.html',
  styleUrls: ['./logo-swiper.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [IonicModule, CommonModule, IncludedFilterPipe],
})
export class LogoSwiperComponent {
  @Input()
  public logoCollection: Logos = { title: '', logos: [] };

  public breakpoints: {
    [width: number]: SwiperOptions;
    [ratio: string]: SwiperOptions;
  } = {
    576: {
      slidesPerView: 2,
      spaceBetween: 16,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 19,
    },
    992: {
      slidesPerView: 4,
      spaceBetween: 22,
    },
    1200: {
      slidesPerView: 5,
      spaceBetween: 24,
    },
  };
}
