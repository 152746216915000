import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Data6, Data7, MediaLogoSchema } from 'src/app/api-model/api-schema';
import { CollectionsSchema } from 'src/app/api-model/collection-schema';
import { Logo, Logos } from 'src/app/components/dumb-components/logo-components/logos';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LogoService {
  constructor(private readonly httpClient: HttpClient) {}

  public getPartnerLogos(): Observable<Logos> {
    return this.getLogos('Partner', 'partnerlogos');
  }

  public getTechLogos(): Observable<Logos> {
    return this.getLogos('Technologie', 'uncropped');
  }

  public getSupporterLogos(): Observable<Logos> {
    return this.getLogos('Förderer', 'uncropped');
  }

  protected getLogos(logoType: string, imageStyle: string): Observable<Logos> {
    return this.httpClient
      .get<CollectionsSchema<MediaLogoSchema>>(
        `${environment.apiUrl}media/logo?filter[field_logo_type.name]=${logoType}&include=field_media_image`
      )
      .pipe(
        map((logos) => {
          const inlinedLogos: Logo[] = [];

          for (const logo of logos.data) {
            const meta = this.getMetaData(logo);
            const inlinedLogo: Logo = {
              alt: meta.alt,
              src: this.getSrcLink(logo, logos['included'] as Data6[], imageStyle),
              title: logo.attributes?.name ?? '',
            };

            inlinedLogos.push(inlinedLogo);
          }

          return { title: '', logos: inlinedLogos };
        })
      );
  }

  private getMetaData(logo: Data7): { alt: string; title: string } {
    const imageData = logo.relationships?.field_media_image;

    if (!imageData?.data || !imageData.data['meta']) {
      return { alt: '', title: '' };
    }

    const metaData: { alt: string; title: string } = imageData.data['meta'] as {
      alt: string;
      title: string;
    };

    return metaData;
  }

  private getSrcLink(logo: Data7, included: Data6[], imageStyle: string): string {
    const linkedImage = included
      .filter((entity) => {
        return entity.id === logo.relationships?.field_media_image?.data?.id;
      })
      .pop();

    if (!linkedImage?.attributes?.image_style_uri) {
      return '';
    }

    return linkedImage.attributes.image_style_uri[imageStyle] as string;
  }
}
