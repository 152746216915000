import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Input,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { DynamicElement } from './dynamic-element';

@Component({
  standalone: true,
  selector: 'app-dynamic-component',
  templateUrl: './dynamic-component.component.html',
})
export abstract class DynamicComponent<SchemaType, BaseType extends DynamicElement<SchemaType>>
  implements AfterViewInit
{
  @Input({ required: true })
  public data!: SchemaType;

  @ViewChild('container', { read: ViewContainerRef })
  public container!: ViewContainerRef;

  protected dynamicInstance?: ComponentRef<BaseType>;

  constructor(protected readonly changeDetection: ChangeDetectorRef) {}

  public ngAfterViewInit(): void {
    this.dynamicInstance = this.container.createComponent(this.getComponentClass());
    this.dynamicInstance.instance.setData(this.data);
    this.changeDetection.detectChanges();
  }

  protected abstract getComponentClass(): Type<BaseType>;
}
