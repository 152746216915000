import { Directive, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  standalone: true,
  selector: '[appLinkify]',
})
export class LinkifyDirective {
  constructor(private router: Router) {}

  @HostListener('click', ['$event'])
  public async onClick(event: PointerEvent & { target: HTMLAnchorElement }): Promise<void> {
    const href: string | null = event.target.getAttribute('href');

    if (!href || href.startsWith('http')) {
      return;
    }

    event.preventDefault();
    await this.router.navigateByUrl(href);
  }
}
