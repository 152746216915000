<ion-menu content-id="main-content" side="end" type="overlay" mode="md">
  <ion-header>
    <ion-toolbar>
      <ion-title>
        <ion-img src="../../../../assets/images/logo_with_room.png" alt="Logo"></ion-img>
      </ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content color="dark">
    <ng-template
      [ngTemplateOutlet]="menuList"
      [ngTemplateOutletContext]="{ entries: this.mainNav }"
    />
  </ion-content>

  <ion-footer>
    <ng-template
      [ngTemplateOutlet]="menuList"
      [ngTemplateOutletContext]="{ entries: this.footerNav }"
    />
  </ion-footer>
</ion-menu>

<ng-template #menuList let-entries="entries">
  <ion-list color="dark" lines="full" class="ion-padding-start">
    <ion-menu-toggle *ngFor="let menuEntry of entries" auto-hide="true">
      <ion-item color="dark" [routerLink]="[menuEntry.route]" routerDirection="forward"
        >{{ menuEntry.label }}
        <ion-icon
          color="primary"
          [name]="this.menuEntry.icon"
          aria-hidden="true"
          slot="start"
        ></ion-icon>
      </ion-item>
    </ion-menu-toggle>
  </ion-list>
</ng-template>
