import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { InputFormFieldComponent } from '../input-form-field/input-form-field.component';

@Component({
  standalone: true,
  selector: 'app-textarea-form-field',
  templateUrl: 'textarea-form-field.component.html',
  imports: [IonicModule, FormsModule, ReactiveFormsModule, CommonModule],
})
export class TextareaFormFieldComponent extends InputFormFieldComponent {}
