import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Observable, map } from 'rxjs';
import { WebformNamedFieldData } from 'src/app/api-model/webform-schema';
import { FormService } from 'src/app/services/form/form.service';

import { FormComponent } from '../../dumb-components/form/form.component';

@Component({
  standalone: true,
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  imports: [FormComponent, CommonModule],
})
export class ContactFormComponent {
  public formId = 'contact';
  public formData: Observable<WebformNamedFieldData[]>;
  public submitText = 'Wir haben Ihre Anfrage entgegengenommen, vielen Dank.';
  public formTitle = 'Kontaktieren Sie uns!';
  public formDescription =
    'Wir melden uns bei Ihnen, um einen kostenlosen Termin zur Erstberatung mit Ihnen zu vereinbaren.';

  constructor(private readonly formService: FormService) {
    this.formData = this.formService.getForm(this.formId).pipe(
      map((response) => {
        const fields: WebformNamedFieldData[] = [];
        for (const [name, field] of Object.entries(response)) {
          field['#name'] = name;
          fields.push(field as WebformNamedFieldData);
        }
        return fields;
      })
    );
  }
}
