import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { register } from 'swiper/element/bundle';

import { MetaDataService } from './services/meta-data/meta-data.service';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly metaDataService: MetaDataService
  ) {
    this.router.events
      .pipe(
        filter((event) => {
          return event instanceof NavigationEnd;
        })
      )
      .subscribe(() => {
        const route = this.getChild(this.activatedRoute);

        route.data.subscribe((data) => {
          this.metaDataService.updateTags({
            pageTitle: data['pageTitle'] as string,
            description: data['description'] as string,
            image: data['image'] as string,
            keywords: data['keywords'] as string,
          });
        });
      });
  }

  private getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    }

    return activatedRoute;
  }
}
