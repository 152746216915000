import { Component } from '@angular/core';
import { Observable, map } from 'rxjs';
import { BlockContentBasicSchema } from 'src/app/api-model/api-schema';
import { BlocksService } from 'src/app/services/blocks/blocks.service';

@Component({
  selector: 'app-base-block',
  template: '<div>Abstract Class!</div>',
})
export abstract class BaseBlockComponent {
  public title: Observable<string>;
  public body: Observable<string>;

  protected block: Observable<BlockContentBasicSchema['data']>;

  constructor(private blockService: BlocksService) {
    this.block = this.blockService.getBlock(this.getId());
    this.title = this.block.pipe(map((data) => this.getBlockTitle(data)));
    this.body = this.block.pipe(map((data) => this.getBlockBody(data)));
  }

  protected abstract getId(): string;

  protected getBlockBody(block: BlockContentBasicSchema['data']): string {
    if (!block.attributes?.body?.value) {
      throw new TypeError('Invalid block without body.');
    }

    return block.attributes.body.value;
  }

  protected getBlockTitle(block: BlockContentBasicSchema['data']): string {
    if (!block.attributes?.info) {
      throw new TypeError('Invalid block without info.');
    }

    return block.attributes.info;
  }
}
