import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
} from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { WebformNamedFieldData } from 'src/app/api-model/webform-schema';
import { environment } from 'src/environments/environment';

import { FormFieldComponent } from './form-field/form-field.component';

type ValueType = string | number | string[] | number[];
interface WebformSubmitJson {
  webform_id: string;
  [k: string]: ValueType;
}
@Component({
  standalone: true,
  selector: 'app-form',
  templateUrl: './form.component.html',
  imports: [CommonModule, FormsModule, ReactiveFormsModule, IonicModule, FormFieldComponent],
  styleUrls: ['form.component.scss'],
})
export class FormComponent implements OnChanges {
  @Input({ required: true })
  public formId = '';

  @Input({ required: true })
  public formData: WebformNamedFieldData[] = [];

  @Input()
  public submitText = '';

  @Input()
  public formTitle = '';

  @Input()
  public formDescription = '';

  public form: FormGroup = this.formBuilder.group({});
  public fields: WebformNamedFieldData[] = [];
  public submitted = false;

  constructor(private readonly formBuilder: FormBuilder, private readonly httpClient: HttpClient) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['formData']) {
      this.createFormFields(this.formData);
    }
  }

  public createFormFields(formData: WebformNamedFieldData[]): void {
    for (const element of formData) {
      this.fields.push(element);
    }
  }

  public onSubmit(): void {
    if (this.form.valid) {
      const submitJson: WebformSubmitJson = {
        webform_id: this.formId,
      };
      for (const [name, control] of Object.entries(this.form.controls)) {
        submitJson[name] = control.value as ValueType;
      }
      this.httpClient.post(`${environment.baseUrl}webform_rest/submit`, submitJson).subscribe({
        complete: () => (this.submitted = true),
        error: () => {
          this.submitted = true;
          this.submitText =
            'Ein Fehler ist bei der Übermittlung Ihrer Daten aufgetreten, bitte versuchen Sie es erneut.';
        },
      });
    } else {
      for (const field of this.fields) {
        const errors = this.form.get(field['#name'])?.errors;
        if (errors) {
          field['#errors'] = this.getErrorMessages(field['#name'], errors);
        }
      }
    }
  }

  private getErrorMessages(fieldName: string, errors: ValidationErrors): string[] {
    // TODO not sure about type

    return errors[fieldName] as string[];
  }
}
