import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { ContactFormComponent } from '../../form/contact-form/contact-form.component';

@Component({
  standalone: true,
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  imports: [CommonModule, IonicModule, ContactFormComponent],
})
export class ModalComponent {
  public static counter = 0;
  public suffix = this.router.url;

  constructor(private readonly router: Router) {}
}
