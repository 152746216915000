import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule),
    data: {
      pageTitle: 'HaKiGo – Nachhaltige Softwareentwicklung',
      description:
        'Die HaKiGo GmbH unterstützt Unternehmen mit nachhaltigen Dienstleistungen im Breich der Softwareentwicklung. Hybride Applikationen sind unser Schwerpunkt, Prozesse sind unsere Leidenschaft.',
      image: `${environment.baseUrl}assets/images/logo_with_room.png`,
      keywords:
        'Software-Entwicklung, Hybride Anwendungen, Web-Anwendungen, Web-Entwicklung, App-Entwicklung, Informatik, SonarQube, GitLab, CI/CD, Ionic, Angular, Drupal, Typescript, PHP',
    },
  },
  {
    path: 'impressum',
    loadChildren: () =>
      import('./pages/impressum/impressum.module').then((m) => m.ImpressumPageModule),
    data: {
      pageTitle: 'Impressum – HaKiGo GmbH',
      description: 'Impressum der HaKiGo GmbH gemäß §5(1) TMG, §6 MdStV, §55 RStV, V.i.s.d.P.',
      image: `${environment.baseUrl}assets/images/logo_with_room.png`,
      keywords: '',
    },
  },
  {
    path: 'angebot',
    loadChildren: () => import('./pages/angebot/angebot.module').then((m) => m.AngebotPageModule),
  },
  {
    path: 'team',
    loadChildren: () => import('./pages/team/team.module').then((m) => m.TeamPageModule),
  },
  {
    path: 'datenschutz',
    loadChildren: () =>
      import('./pages/datenschutz/datenschutz.module').then((m) => m.DatenschutzPageModule),
    data: {
      pageTitle: 'Datenschutz – HaKiGo GmbH',
      description: 'Datenschutzerklärung der HaKiGo GmbH',
      image: `${environment.baseUrl}assets/images/logo_with_room.png`,
      keywords: '',
    },
  },
  {
    path: 'kontakt',
    loadChildren: () => import('./pages/kontakt/kontakt.module').then((m) => m.KontaktPageModule),
    data: {
      pageTitle: 'Kontakt – hakigo',
      description:
        'Rufen Sie uns einfach an oder schreiben Sie uns eine Nachricht und wir melden uns bei Ihnen.',
      image: `${environment.baseUrl}assets/icon/favicon.png`,
      keywords: '',
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
