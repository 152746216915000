import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, ValidatorFn } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { BaseFormFieldComponent } from '../base-form-field/base-form-field.component';

@Component({
  standalone: true,
  selector: 'app-button-form-field',
  templateUrl: './button-form-field.component.html',
  imports: [IonicModule, FormsModule, ReactiveFormsModule],
})
export class ButtonFormFieldComponent extends BaseFormFieldComponent implements OnInit {
  public override getValidators(): ValidatorFn[] {
    return [];
  }

  public ngOnInit(): void {
    if (this.field['#type'] === 'webform_actions') {
      this.field['#type'] = 'submit';
      this.field['#title'] = this.field['#submit__label'] as string;
    }
  }
}
