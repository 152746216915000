<ion-button [id]="'open-modal-' + this.suffix" size="default">Hier entlang</ion-button>
<ion-modal
  [animated]="true"
  [trigger]="'open-modal-' + this.suffix"
  [initialBreakpoint]="0.75"
  [breakpoints]="[0, 0.75, 1]"
>
  <ng-template>
    <ion-content>
      <app-contact-form />
    </ion-content>
  </ng-template>
</ion-modal>
